import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
// import {Auth} from 'aws-amplify';
import {Amplify} from 'aws-amplify';
import config from "./config";
import muiTheme from './Theme/muiTheme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ErrorFallback from "./components/ErrorBoundary";
import {ErrorBoundary} from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import {HelmetProvider} from 'react-helmet-async';
// import ReactDOM from "react-dom";
// import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

// const TagManager = React.lazy(() => import('../node_modules/react-gtm-module'));

const tagManagerArgs =
    {
        gtmId: 'GTM-T5QQWX3',
            dataLayerName: 'QDataLayer'
    }

process.env.REACT_APP_STAGE === 'prod' && TagManager.initialize(tagManagerArgs)

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
            domain: config.cognito.oauth.domain,
            scope: config.cognito.oauth.scope,
            redirectSignIn: config.cognito.oauth.redirectSignIn,
            redirectSignOut: config.cognito.oauth.redirectSignOut,
            responseType: "code"
        },
        options: {
            // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
            AdvancedSecurityDataCollectionFlag: true
        }
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: []
    }
})

// Auth.configure({
//     Auth: {
//         mandatorySignIn: true,
//         region: config.cognito.REGION,
//         userPoolId: config.cognito.USER_POOL_ID,
//         identityPoolId: config.cognito.IDENTITY_POOL_ID,
//         userPoolWebClientId: config.cognito.APP_CLIENT_ID,
//         // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//         // authenticationFlowType: 'USER_SRP_AUTH',
//         oauth: {
//             domain: config.cognito.oauth.domain,
//             scope: config.cognito.oauth.scope,
//             redirectSignIn: config.cognito.oauth.redirectSignIn,
//             redirectSignOut: config.cognito.oauth.redirectSignOut,
//             responseType: "code"
//         },
//         options: {
//             // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//             AdvancedSecurityDataCollectionFlag: true
//         }
//     },
//     Storage: {
//         region: config.s3.REGION,
//         bucket: config.s3.BUCKET,
//         identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     },
//     API: {
//         endpoints: []
//     }
// });


//
// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container);
//
//
// root.render(   <React.StrictMode>
// <Router>
//
//         <ThemeProvider theme={muiTheme}>
//             <ErrorBoundary FallbackComponent={ErrorFallback}>
//             <App/>
//             </ErrorBoundary>
//         </ThemeProvider>
// </Router></React.StrictMode>);

// const container = document.getElementById('app');
// const root = createRoot(container);

// REACT 18 CODE
// root.render(
//   <React.StrictMode>
//       <Router>
//         <ThemeProvider theme={muiTheme}>
//             <ErrorBoundary FallbackComponent={ErrorFallback}>
//             <App/>
//             </ErrorBoundary>
//         </ThemeProvider>
//       </Router>
//   </React.StrictMode>
//     )

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//       <Router>
//
//         <ThemeProvider theme={muiTheme}>
//             <ErrorBoundary FallbackComponent={ErrorFallback}>
//     <App />
//             </ErrorBoundary>
//         </ThemeProvider>
//       </Router>
//   </React.StrictMode>
// );

// REACT 17 CODE
// ReactDOM.render(
//     <React.StrictMode>
//         <Router>
//             <StyledEngineProvider injectFirst>
//                 <ThemeProvider theme={muiTheme}>
//                     <HelmetProvider>
//                         <ErrorBoundary FallbackComponent={ErrorFallback}>
//                             <App/>
//                         </ErrorBoundary>
//                     </HelmetProvider>
//                 </ThemeProvider>
//             </StyledEngineProvider>
//         </Router>
//     </React.StrictMode>,
//     document.getElementById("root")
// );
// reportWebVitals();


window.addEventListener('error', (e) => {
  if (e.message && /Loading chunk \d+ failed/.test(e.message)) {
    console.error('Chunk Load Error: ', e.message);
    // Optionally display a user-friendly message
    // alert('A new version of the app is available. Please refresh the page.');
    window.location.reload(); // Force a reload to fetch new chunks
  }
});

// REACT 18 CODE

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
     <React.StrictMode>
        <Router>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <HelmetProvider>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <App />
                                </ErrorBoundary>
                    </HelmetProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Router>
     </React.StrictMode>
);

// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render( <Router><ThemeProvider theme={muiTheme}>
//     <ErrorBoundary FallbackComponent={ErrorFallback}><App /></ErrorBoundary></ThemeProvider></Router>);
//
// const root = document.getElementById("app");
// render(<App />, root);

// // const container =
//     ReactDOM.createRoot(document.getElementById('app')).render(<Router>
//     <ThemeProvider theme={muiTheme}>
//     <ErrorBoundary FallbackComponent={ErrorFallback}>
//         <App />
//     </ErrorBoundary>
//     </ThemeProvider></Router>);
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
//
// // serviceWorker.unregister()

