
const stage = process.env.REACT_APP_STAGE

const PlanPermissions =
    stage === 'prod' ?
        {"basic": {
                'scenarios': {hasAccess: false},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: false},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: false},
                'sales-effort': {hasAccess: false},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: false},
                'pclose': {hasAccess: false},
                "access-denied": {hasAccess: true},
            },

            "professional": {
                  'scenarios': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'winflows': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'config': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'sales-productivity': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'forecast': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pclose': {hasAccess: true},
            "access-denied": {hasAccess: true},
            },

                     "enterprise": {
                  'scenarios': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'winflows': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'config': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'sales-productivity': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'forecast': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pclose': {hasAccess: true},
            "access-denied": {hasAccess: true},
            },


            "sales": {
                'scenarios': {hasAccess: true},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: true},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: true},
                'sales-effort': {hasAccess: true},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },

            "marketing": {
                'scenarios': {hasAccess: true},
                'programeffectiveness': {hasAccess: true},
                'winflows': {hasAccess: true},
                'pipelineflows': {hasAccess: true},
                'dealexplorer': {hasAccess: true},
                'config': {hasAccess: true},
                'inbound-follow-up': {hasAccess: true},
                'sales-effort': {hasAccess: true},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },
            "business": {
                'scenarios': {hasAccess: true},
                'programeffectiveness': {hasAccess: true},
                'winflows': {hasAccess: true},
                'pipelineflows': {hasAccess: true},
                'dealexplorer': {hasAccess: true},
                'config': {hasAccess: true},
                'inbound-follow-up': {hasAccess: true},
                'sales-effort': {hasAccess: true},
                'sales-ramp': {hasAccess: true},
                'sales-productivity': {hasAccess: true},
                'win-rate': {hasAccess: true},
                'forecast': {hasAccess: true},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },
            "e34e494c-86e9-41db-96ab-fcb651a4ddc0": {
                'scenarios': {hasAccess: false},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: false},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: true},
                'sales-effort': {hasAccess: false},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: true},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },
            //             "bef60fff-e3ac-467f-b71c-3cf45a93a733": {
            //     'scenarios': {hasAccess: false},
            //     'programeffectiveness': {hasAccess: false},
            //     'winflows': {hasAccess: false},
            //     'pipelineflows': {hasAccess: false},
            //     'dealexplorer': {hasAccess: true},
            //     'config': {hasAccess: true},
            //     'inbound-follow-up': {hasAccess: true},
            //     'sales-effort': {hasAccess: true},
            //     'sales-ramp': {hasAccess: false},
            //     'sales-productivity': {hasAccess: true},
            //     'win-rate': {hasAccess: true},
            //     'forecast': {hasAccess: true},
            //     'pconvert': {hasAccess: true},
            //     'pclose': {hasAccess: true},
            //     "access-denied": {hasAccess: true},
            // },
            "4ac8e7f0-99e0-4a8b-a052-1bec2235cb3f": {
                'scenarios': {hasAccess: false},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: false},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: true},
                'sales-effort': {hasAccess: false},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },
            "9f3508e0-af68-4301-8227-be44dd60015d": {
                'scenarios': {hasAccess: false},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: false},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: false},
                'sales-effort': {hasAccess: false},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: true},
                'pclose': {hasAccess: true},
                "access-denied": {hasAccess: true},
            },
        }
        :
        // otherwise no specific plan
        {
       "basic": {
                'scenarios': {hasAccess: false},
                'board-stats': {hasAccess: false},
                'programeffectiveness': {hasAccess: false},
                'winflows': {hasAccess: false},
                'pipelineflows': {hasAccess: false},
                'dealexplorer': {hasAccess: false},
                'config': {hasAccess: false},
                'inbound-follow-up': {hasAccess: false},
                'sales-effort': {hasAccess: false},
                'sales-ramp': {hasAccess: false},
                'sales-productivity': {hasAccess: false},
                'win-rate': {hasAccess: false},
                'forecast': {hasAccess: false},
                'pconvert': {hasAccess: false},
                'pclose': {hasAccess: false},
                "access-denied": {hasAccess: true},
            },

            "professional": {
                  'scenarios': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'winflows': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'config': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'sales-productivity': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'forecast': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pclose': {hasAccess: true},
            "access-denied": {hasAccess: true},
            },

                     "enterprise": {
                  'scenarios': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'winflows': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'config': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'sales-productivity': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'forecast': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pclose': {hasAccess: true},
            "access-denied": {hasAccess: true},
            },
    };

export default PlanPermissions;