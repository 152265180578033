import React, {lazy, Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AsyncAppliedRoute from "./components/AppliedRoute"
import {Redirect} from "react-router";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const AsyncAllIntegrationsContainer = lazy(() => import("./containers/Admin/ProvisionCompany/AllIntegrationsContainer"));

const AsyncHome = lazy(() => import("./containers/Home/Home"));
const AsyncNotFound = lazy(() => import("./containers/NotFound"));
const AsyncLogin = lazy(() => import("./containers/LoginScreen"));
const AsyncSignup = lazy(() => import("./containers/Signup/Signup"));
const AsyncAccountView = lazy(() => import("./containers/Accounts/AccountView"));
const AsyncRules = lazy(() => import("./containers/Rules/Rules"));
const AsyncResetPassword = lazy(() => import("./containers/ResetPassword"));
const AsyncSettings = lazy(() => import("./containers/Settings"));
const AsyncAuthenticatedBillingRedux = lazy(() => import("./containers/Billing/AuthenticatedBillingRedux"));
const AsyncSubscriptionChangeSuccessRedux = lazy(() => import("./containers/Billing/SubscriptionChangeSuccessRedux"));
const AsyncUnauthenticatedBillingRedux = lazy(() => import("./containers/Billing/UnauthenticatedBillingRedux"));
const AsyncUpgradeContainerRedux = lazy(() => import("./containers/Billing/UpgradeContainerRedux"));
const AsyncChangePassword = lazy(() => import("./containers/ChangePassword"));
const AsyncChangeEmail = lazy(() => import("./containers/ChangeEmail"));
const AsyncInboundResponse = lazy(() => import("./components/Diagrams/InboundResponseData"));
const AsyncSalesForecast = lazy(() => import("./components/Diagrams/SalesForecastData"));
const AsyncDashboard = lazy(() => import("./components/Diagrams/DashboardData"));
const AsyncWinAttribution = lazy(() => import("./components/Diagrams/WinAttribution"));
const AsyncPipelineAttribution = lazy(() => import("./components/Diagrams/PipelineAttribution"));
const AsyncPConvert = lazy(() => import("./components/Diagrams/PConvert"));
const AsyncPClose = lazy(() => import("./components/Diagrams/PClose"));
const AsyncRulesData = lazy(() => import("./components/Diagrams/RulesData"));
const AsyncProgramEffectivenessData = lazy(() => import("./components/Diagrams/ProgramEffectivenessData"));
const AsyncSalesEffortData = lazy(() => import("./components/Diagrams/SalesEffortData"));
const AsyncSalesRampData = lazy(() => import("./components/Diagrams/SalesRampData"));
const AsyncSalesWinRateTrendsData = lazy(() => import("./components/Diagrams/SalesWinRateTrendsData"));
const AsyncFAQContainer = lazy(() => import("./containers/FAQs/FAQContainer"));
const AsyncUsersData = lazy(() => import("./components/Diagrams/UsersData"));
const AsyncNewUser = lazy(() => import("./containers/Company/NewUser"));
const AsyncUpdateUser = lazy(() => import("./containers/Company/UpdateUser"));
const AsyncCompanySettingsPage = lazy(() => import("./containers/Company/CompanySettings"));
const AsyncAccessDenied = lazy(() => import("./containers/AccessDenied"));
const AsyncAcceptInvite = lazy(() => import("./containers/Company/AcceptInvite"));
const AsyncVerifyEmail = lazy(() => import("./containers/VerifyEmail"));
const AsyncCompanyProvisionStepper = lazy(() => import("./containers/Admin/ProvisionCompany/CompanyProvisionStepper"));
const AsyncCompanyConfigureDataStepper = lazy(() => import("./containers/Admin/ConfigureCompanyData/CompanyConfigureDataStepper"));
const AsyncTermsContainer = lazy(() => import("./containers/ServiceTerms/TermsContainer"));
const AsyncSecurityContainer = lazy(() => import("./containers/Security/SecurityPrivacyArchitectureContainer"));
const AsyncSalesProductivityData = lazy(() => import("./components/Diagrams/SalesProductivityData"));
const AsyncMarketingProductPage = lazy(() => import("./containers/MarketingPages/MarketingProduct"));
const AsyncMarketingAttributionPage = lazy(() => import("./containers/MarketingPages/MarketingAttribution"));
const AsyncMarketingCampaignsPage = lazy(() => import("./containers/MarketingPages/MarketingCampaigns"));
const AsyncMarketingSalesRampPage = lazy(() => import("./containers/MarketingPages/MarketingSalesRamp"));
const AsyncMarketingSalesEffortPage = lazy(() => import("./containers/MarketingPages/MarketingSalesEffort"));
const AsyncMarketingSalesForecastsPage = lazy(() => import("./containers/MarketingPages/MarketingSalesForecasts"));
const AsyncMarketingSalesProductivityPage = lazy(() => import("./containers/MarketingPages/MarketingSalesProductivity"));
const AsyncMarketingMLRecipesPage = lazy(() => import("./containers/MarketingPages/MarketingMLRecipes"));
const AsyncMarketingContactUs = lazy(() => import("./containers/MarketingPages/ContactUs/ContactUsContainer"));
const AsyncMarketingInboundFollowUp = lazy(() => import("./containers/MarketingPages/MarketingInboundFollowUp.js"));
const AsyncMarketingSolutionsSales = lazy(() => import("./containers/MarketingPages/MarketingSolutionSales.js"));
const AsyncMarketingSolutionsMarketing = lazy(() => import("./containers/MarketingPages/MarketingSolutionMarketing.js"));
const AsyncMarketingSolutionRevOps = lazy(() => import("./containers/MarketingPages/MarketingSolutionRevOps.js"));
const AsyncMarketingSolutionFinance = lazy(() => import("./containers/MarketingPages/MarketingSolutionFinance.js"));
const AsyncSalesForecastSetup = lazy(() => import("./containers/SalesForecast/SalesForecastSetup"));
const AsyncInboundResponseSetup = lazy(() => import("./containers/InboundResponse/InboundResponseSetup"));
const AsyncSalesQuotaSetup = lazy(() => import("./containers/SalesForecast/SalesQuotaSetupContainer"));
const AsyncSalesArrSetup = lazy(() => import("./containers/ArrSchedule/ArrScheduleDisplay"));
const AsyncTaskLabelSetup = lazy(() => import("./containers/SalesEffort/TaskLabelSetup"));
const AsyncSamlRedirect = lazy(() => import("./containers/SamlRedirect"));
const AsyncMarketingPartners = lazy(() => import("./containers/MarketingPages/MarketingPartners"));
const AsyncMarketingCaseStudyActon = lazy(() => import("./containers/MarketingPages/CaseStudies/CaseStudyActOn"));
const AsyncMarketingPartnerArcTech = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerArcTechnologies"));
const AsyncMarketingPartnerLevelUpFinance = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerLevelUpFinance"));
const AsyncPartnerCliffSimon = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerCliffSimon"));
const AsyncPartnerRevOpsAf = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerRevOpsAf"));
// const AsyncPartnerRevOpsAfMeet = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerRevOpsAfMeet"));
const AsyncPartnerFutureFinance = lazy(() => import("./containers/MarketingPages/PartnerLandingPages/PartnerFutureFinance"));
const AsyncAttributionAdvancedSetup = lazy(() => import("./containers/Attribution/AttributionAdvancedSetup"));
const AsyncMetaManager = lazy(() => import("./containers/MetaManager/MetaManager"));
const AsyncGtmStatsContainer = lazy(() => import("./containers/GtmStats/GtmStatsContainer"));
const AsyncBoardStatsContainer = lazy(() => import("./containers/BoardStats/BoardStatsContainer"));
const AsyncGtmStatsSegmentManagerContainer = lazy(() => import("./containers/GtmStats/GtmStatsSegmentManager"));
const AsyncRevenueDefinitionSegmentManagerContainer = lazy(() => import("./containers/CustomerInvoices/RevenueDefinitionSegmentManager"));
const AsyncExecutiveSummary = lazy(() => import("./containers/ExecSummary/ExecSummary"));
const AsyncOppCreationContainer = lazy(() => import("./containers/OppCreationStats/OppCreationContainer"));
const AsyncPreOppConfig = lazy(() => import("./containers/ProgramEffectiveness/PreOppConfig"));
const AsyncMarketingBusinessDigest = lazy(() => import("./containers/MarketingPages/MarketingBusinessDigest"));
const AsyncMarketingGtmStats = lazy(() => import("./containers/MarketingPages/MarketingGtmStats"));
const AsyncMarketingMultiQuarterProjections = lazy(() => import("./containers/MarketingPages/MarketingMultiQuarterProjections"));
const AsyncMarketingLeadership = lazy(() => import("./containers/MarketingPages/MarketingLeadershipPage"));
const AsyncHubSpotPublicDocumentationContainer = lazy(() => import("./containers/PublicDocumentation/HubSpotPublicDocumentationContainer"));
const AsyncGongPublicDocumentationContainer = lazy(() => import("./containers/PublicDocumentation/GongPublicDocumentationContainer"));
const AsyncNetsuitePublicDocumentationContainer = lazy(() => import("./containers/PublicDocumentation/NetSuitePublicDocumentationContainer"));
const AsyncCustomerInvoices = lazy(() => import("./containers/CustomerInvoices/CustomerInvoices"));
const AsyncDepartmentSpend = lazy(() => import("./containers/DepartmentSpend/DepartmentSpend"));
const AsyncDepartmentSetup = lazy(() => import("./containers/DepartmentSpend/DepartmentSetup"));
const AsyncPromptManager = lazy(() => import("./containers/PromptManager/PromptManagerContainer"));
const AsyncConnectSlack = lazy(() => import("./containers/Admin/ConnectSlack"));
const AsyncConnectGong = lazy(() => import("./containers/Admin/ConnectGong"));
const AsyncTrialEndedSplash = lazy(() => import("./containers/TrialEnded/TrialEndedSplash"));
const AsyncCareersPage = lazy(() => import("./containers/Careers/CareersPage"));
const AsyncGtmFinanceDinnerSeptember2024 = lazy(() => import("./containers/MarketingPages/EventLandingPages/GtmFinanceDinnerSeptember2024"));

export default function Routes({appProps}) {

    function RedirectWithStatus({from, to, status}) {
        return (
            <Route
                render={({staticContext}) => {
                    // there is no `staticContext` on the client, so
                    // we need to guard against that here
                    if (staticContext) staticContext.status = status;
                    return <Redirect from={from} to={to}/>;
                }}
            />
        );
    }


    return (

        // <Suspense fallback={<div style={{paddingTop: '5em'}}>loading...</div>}>
        <Switch>

            <RedirectWithStatus status={301} from="/revopsaf/meet"
                                to="/revopsaf"/>
            <RedirectWithStatus status={301} from="/sign-up"
                                to="/signup"/>
            <RedirectWithStatus status={301} exact from="/products"
                                to="/products/integrations"/>
            <RedirectWithStatus status={301} from="/products/inbound-lead-response"
                                to="/products/inbound-lead-response-measurement"/>
            <RedirectWithStatus status={301} exact from="/products"
                                to="/products/integrations"/>
            <RedirectWithStatus status={301} from="/products/sales-productivity"
                                to="/products/sales-pipeline-analytics"/>
            <RedirectWithStatus status={301} from="/products/sales-activity" to="/products/sales-activity-analytics"/>
            <RedirectWithStatus status={301} from="/products/sales-forecasting" to="/products/forecasting"/>
            <RedirectWithStatus status={301} from="/products/sales-pipeline-analytics-analytics"
                                to="/products/sales-pipeline-analytics"/>
            <RedirectWithStatus status={301} from="/products/attribution" to="/products/account-based-attribution"/>
            <RedirectWithStatus status={301} from="/service-terms" to="/terms/service-terms"/>
            <RedirectWithStatus status={301} from="/privacy-policy" to="/terms/privacy-policy"/>
            <RedirectWithStatus status={301} from="/products/sales-productivity-analytics"
                                to="/products/sales-pipeline-analytics"/>

            <Suspense fallback={<Container sx={{height: "80vh"}}
                                           maxWidth={"xl"}>
                <Box/>
            </Container>}>
                <AsyncAppliedRoute path="/" exact component={AsyncHome}  />
                <UnauthenticatedRoute path="/signup/:competitorRenewalCampaign?/:step?/:signupEmail?/:codeWasSent?"
                                      exact
                                      component={AsyncSignup}
                                       />
                <UnauthenticatedRoute path="/about" exact component={AsyncMarketingLeadership}
                                      />
                <UnauthenticatedRoute path="/login" exact component={AsyncLogin}
                                      />
                <UnauthenticatedRoute path="/login/reset" exact component={AsyncResetPassword}
                                      />
                <UnauthenticatedRoute path="/login/set-password/:invitedEmail?/:codeWasSent?" exact
                                      component={AsyncAcceptInvite}  />
                <UnauthenticatedRoute path="/verify-email" exact component={AsyncVerifyEmail}
                                      />
                <UnauthenticatedRoute path="/products/integrations" exact component={AsyncMarketingProductPage}

                                      />
                <UnauthenticatedRoute path="/products/account-based-attribution" exact
                                      component={AsyncMarketingAttributionPage}  />
                <UnauthenticatedRoute path="/products/campaign-measurement" exact
                                      component={AsyncMarketingCampaignsPage}
                                       />
                <UnauthenticatedRoute path="/products/sales-activity-analytics" exact
                                      component={AsyncMarketingSalesEffortPage}  />
                <UnauthenticatedRoute path="/products/sales-pipeline-analytics" exact
                                      component={AsyncMarketingSalesProductivityPage}
                                      />
                <UnauthenticatedRoute path="/products/sales-ramp" exact component={AsyncMarketingSalesRampPage}
                                       />
                <UnauthenticatedRoute path="/products/forecasting" exact component={AsyncMarketingSalesForecastsPage}
                                       />
                <UnauthenticatedRoute path="/products/business-digest" exact component={AsyncMarketingBusinessDigest}
                                       />
                <UnauthenticatedRoute path="/products/go-to-market-stats" exact component={AsyncMarketingGtmStats}
                                       />
                <UnauthenticatedRoute path="/products/multi-quarter-projections" exact
                                      component={AsyncMarketingMultiQuarterProjections}
                                       />

                <UnauthenticatedRoute path="/products/machine-learning" exact component={AsyncMarketingMLRecipesPage}
                                       />
                <UnauthenticatedRoute path="/products/inbound-lead-response-measurement" exact
                                      component={AsyncMarketingInboundFollowUp}  />
                <UnauthenticatedRoute path="/solutions/sales" exact component={AsyncMarketingSolutionsSales}
                                       />
                <UnauthenticatedRoute path="/solutions/finance" exact
                                      component={AsyncMarketingSolutionFinance}
                                       />
                <UnauthenticatedRoute path="/solutions/revenue-operations" exact
                                      component={AsyncMarketingSolutionRevOps}
                                       />

                <UnauthenticatedRoute path="/solutions/revenue-teams" exact
                                      component={AsyncMarketingSolutionRevOps}
                                       />


                <UnauthenticatedRoute path="/solutions/marketing" exact component={AsyncMarketingSolutionsMarketing}
                                       />
                <UnauthenticatedRoute path="/partners" exact component={AsyncMarketingPartners}
                                       />

                <UnauthenticatedRoute path="/case-studies/act-on" exact component={AsyncMarketingCaseStudyActon}
                                       />

                <UnauthenticatedRoute path="/partners/arc" exact component={AsyncMarketingPartnerArcTech}
                                       />

                <UnauthenticatedRoute path="/partners/levelup-finance" exact component={AsyncMarketingPartnerLevelUpFinance}
                                       />


                <UnauthenticatedRoute path="/partners/cliff-simon" exact component={AsyncPartnerCliffSimon}
                                       />
                <UnauthenticatedRoute path="/revopsaf" exact component={AsyncPartnerRevOpsAf}
                                       />

                <UnauthenticatedRoute path="/future-finance" exact component={AsyncPartnerFutureFinance}
                                       />

                <UnauthenticatedRoute path="/pricing" exact component={AsyncUnauthenticatedBillingRedux}
                                      />

                <Route path="/contact-us/:repName?" exact component={AsyncMarketingContactUs}
                       />
                <Route path="/security" exact component={AsyncSecurityContainer}
                       />

                <Route path="/public-docs/hubspot-integration" exact
                       component={AsyncHubSpotPublicDocumentationContainer}
                       />

                <Route path="/public-docs/gong-integration" exact
                       component={AsyncGongPublicDocumentationContainer}
                       />

                <Route path="/public-docs/netsuite-integration" exact
                       component={AsyncNetsuitePublicDocumentationContainer}
                       />

                <Route path="/terms/:tab?" exact component={AsyncTermsContainer}
                       />

                <AuthenticatedRoute path="/settings/password" exact component={AsyncChangePassword}
                                    />
                <AuthenticatedRoute path="/access-denied/:reason?" exact component={AsyncAccessDenied}

                                    />
                <AuthenticatedRoute path={"/trial-ended"} exact component={AsyncTrialEndedSplash}

                                    />
                <AuthenticatedRoute path="/settings/email" exact component={AsyncChangeEmail}
                                    />
                <AuthenticatedRoute path="/settings" exact component={AsyncSettings}
                                    />
                <AuthenticatedRoute path="/admin/billing" exact component={AsyncAuthenticatedBillingRedux}
                                     />
                <AuthenticatedRoute path="/admin/billing/checkout/:sPlan?/:sPid?/:sPrice?" exact
                                    component={AsyncUpgradeContainerRedux}  />
                <AuthenticatedRoute path="/admin/billing/success" exact
                                    component={AsyncSubscriptionChangeSuccessRedux}
                                     />
                <AuthenticatedRoute path="/config/program-detail/:id" exact component={AsyncRules}
                                    />
                <AuthenticatedRoute
                    path="/config/programs/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncRulesData}  />
                <AuthenticatedRoute path="/winflows/:ttype?/:arr?/:dType?/:view?/:cfseg?/:custv?/:dopt?" exact
                                    component={AsyncWinAttribution}  />
                <AuthenticatedRoute
                    path="/pipelineflows/:ttype?/:arr?/:dType?/:view?/:cfseg?/:custv?/:dopt?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?/:qCurrentPageToSet?"
                    exact
                    component={AsyncPipelineAttribution}  />
                <AuthenticatedRoute path="/pconvert/:tab?/:custv?/:enforceDType?/:summarizeBy?/:dopt?" exact component={AsyncPConvert}
                                    />
                <AuthenticatedRoute
                    path="/pclose/:tab?/:sourceScenario?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?/:averageDate?/:averageSize?"
                    exact component={AsyncPClose}  />
                <AuthenticatedRoute path="/attribution-setup/:actStep?/:showAdvanced?" exact
                                    component={AsyncAttributionAdvancedSetup}
                                     />


                <AuthenticatedRoute
                    path="/program-effectiveness/:log?/:ss?/:isLink?/:tab?/:cf?/:dopt?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncProgramEffectivenessData}  />
                <AuthenticatedRoute path="/sales-effort/:tab?/:ttype?/:ms?/:ss?/:dopt?/:sids?/:snames?" exact
                                    component={AsyncSalesEffortData}  />
                <AuthenticatedRoute path="/sales-ramp/:tab?/:ttype?/:dopt?/:ms?/:ss?/:sids?/:snames?/:rids?/:rnames?"
                                    exact
                                    component={AsyncSalesRampData}  />
                <AuthenticatedRoute
                    // path="/pipeline-forensics/:tab?/:qGroups?/:ss?/:ti?/:dType?/:ts?/:sids?/:snames?/:isLink?/:dopt?/:ncds?/:ncde?/:pdd?/:custv?/:refQuarter?/:anchDate?"
                    path="/pipeline-forensics/:tab?/:qGroups?/:ss?/:ti?/:dType?/:ts?/:sids?/:snames?/:isLink?/:dopt?/:ncds?/:ncde?/:pdd?/:custv?/:refQuarter?/:anchDate?/:repId?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact
                    component={AsyncSalesProductivityData}  />
                <AuthenticatedRoute
                    path="/sales-productivity"
                    component={({match}) => (
                        <Redirect
                            to={`/pipeline-forensics/`}/>
                    )}/>

                <UnauthenticatedRoute path="/events/gtm-finance-dinner-san-francisco-sept-2024"
                                    exact
                                    component={AsyncGtmFinanceDinnerSeptember2024}  />


                <AuthenticatedRoute path="/win-rate/:ti?/:ss?/:dType?/:dateType?/:custv?/:inclOpen?/:enforceDType?/:dopt?/:qGroups?"
                                    exact
                                    component={AsyncSalesWinRateTrendsData}  />
                <AuthenticatedRoute path="/faq/:faqSlugs?" exact component={AsyncFAQContainer}
                                    />
                <AuthenticatedRoute path="/manage-users/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?" exact component={AsyncUsersData}
                                    />

                <AuthenticatedRoute path="/company/new-user" exact component={AsyncNewUser}
                                    />
                <AuthenticatedRoute path="/company/update-user/:userid?" exact component={AsyncUpdateUser}
                                     />
                <AuthenticatedRoute path="/admin/company/create/:actstep?/:platform?" exact
                                    component={AsyncCompanyProvisionStepper}  />
                <AuthenticatedRoute path="/admin/company/settings" exact
                                    component={AsyncCompanySettingsPage}  />
                <AuthenticatedRoute path="/admin/company/configure-data/:actstep?/:showAdvanced?" exact
                                    component={AsyncCompanyConfigureDataStepper}
                                    />

                <AuthenticatedRoute path="/admin/company/integrations" exact
                                    component={AsyncAllIntegrationsContainer}
                                    />

                <AuthenticatedRoute path="/inbound-follow-up/:ss?/:ttype?/:custv?/:sids?/:dopt?" exact
                                    component={AsyncInboundResponse}  />

                <AuthenticatedRoute
                    path="/forecast/:tab?/:quarter?/:isLink?/:qGroups?/:scId?/:dType?/:isoB?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"

                    exact component={AsyncSalesForecast}  />
                <AuthenticatedRoute
                    path="/scenarios/:tab?/:scId?/:quarterGroup?/:averageSize?/:averageDate?/:goalOpt?/:quotaOpt?/:plugOpt?/:isoSegment?"
                    exact component={AsyncDashboard}  />
                <AuthenticatedRoute path="/forecast-setup/:actStep?/:showExplainer?" exact
                                    component={AsyncSalesForecastSetup}
                                     />
                <AuthenticatedRoute path="/inbound-response-setup/:actStep?" exact component={AsyncInboundResponseSetup}
                                     />
                <AuthenticatedRoute
                    path="/quota-setup/:actStep?/:actQtr?/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncSalesQuotaSetup}  />

                <AuthenticatedRoute
                    path="/arr-schedule/:dopt?/:custv?/:arrSource?"
                    exact component={AsyncSalesArrSetup}  />

                <AuthenticatedRoute
                    path="/customer-invoices/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncCustomerInvoices}  />

                <AuthenticatedRoute
                    path="/department-spend/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncDepartmentSpend}  />

                <AuthenticatedRoute
                    path="/department-definitions/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncDepartmentSetup}  />

                <AuthenticatedRoute
                    path="/manage-prompts/:qSearchTextToSet?/:qRowsPerPageToSet?/:qColumnOrderToSet?/:qColsFiltered?/:qColsVisibleToSet?/:qSortOrderToSet?"
                    exact component={AsyncPromptManager}  />


                                <AuthenticatedRoute
                    path="/connect-gong"
                    exact component={AsyncConnectGong}  />

                                <AuthenticatedRoute
                    path="/connect-slack"
                    exact component={AsyncConnectSlack}  />

                <AuthenticatedRoute path="/manage-task-labels" exact component={AsyncTaskLabelSetup}
                                    />
                <AuthenticatedRoute path="/opp-metadata-setup" exact component={AsyncMetaManager}
                                    />
                <AuthenticatedRoute path="/config/pre-opp" exact component={AsyncPreOppConfig}
                                    />

                <AuthenticatedRoute path="/gtm-stats/:tab?/:custv?/:showAvg?/:groupQtr?/:scId?/:dopt?" exact
                                    component={AsyncGtmStatsContainer}
                                    />
                <AuthenticatedRoute path="/gtm-stats-segments" exact
                                    component={AsyncGtmStatsSegmentManagerContainer}
                                    />


                <AuthenticatedRoute path="/revenue-definitions" exact component={AsyncRevenueDefinitionSegmentManagerContainer}

                                    />

                <AuthenticatedRoute path="/board-stats/:tab?/:custv?/:enforceDType?/:arrSource?/:dopt?" exact
                                    component={AsyncBoardStatsContainer}
                                    />

                <AuthenticatedRoute path="/opp-creation/:dType?/:goalOpt?/:custv?/:dopt?" exact
                                    component={AsyncOppCreationContainer}

                                    />

                <AuthenticatedRoute path="/accounts/:selectedAccountId" exact component={AsyncAccountView}

                                    />
                <AuthenticatedRoute path="/business-digest/:tab?/:digestId?/:startD?/:endD?" exact
                                    component={AsyncExecutiveSummary}

                                    />
                <UnauthenticatedRoute path="/identityredirect/:providerData?" exact component={AsyncSamlRedirect}
                                       />

                <UnauthenticatedRoute path="/careers" exact component={AsyncCareersPage}
                                       />


            </Suspense>
            <Route component={AsyncNotFound}/>
        </Switch>
        // </Suspense>
    )
}

