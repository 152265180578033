import React, {useEffect, useRef} from "react";
import {Alert, Grid, ListItem, ToggleButton} from "@mui/material";
import axios from "axios";
import {config} from "../config";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useEventListener from "@use-it/event-listener";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Drawer from "@mui/material/Drawer";
import {AiOutlineCaretRight} from "react-icons/ai";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import "./qPilotStyles.css";
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import rehypeRaw from "rehype-raw";

const  QBearMascot = "/images/qpilot-avatar-image.png";

export default function QPilotChatbot(props) {

    const [isRunningQPilot, setIsRunningQPilot] = React.useState(false)
    const [isInitializing, setIsInitializing] = React.useState(false)
    const [qPilotResponse, setQPilotResponse] = React.useState([])
    const [currentPrompt, setCurrentPrompt] = React.useState("")

    const handleCurrentPrompt = React.useCallback((e) => {
        setCurrentPrompt(e.target.value)
    }, [])


    const sendChat = async (initial, event) => {
        if (initial) {
            setIsInitializing(true)
        } else {
            setIsRunningQPilot(true)
        }

        setQPilotResponse([{message: currentPrompt, type: "user"}, ...qPilotResponse])
        let incomingPrompt = currentPrompt
        setCommandKeyDown(false)
        setCurrentPrompt("")
        await axios.post(`${config.REACT_APP_BACKEND_HOST}/qpilot-multi-source`, {
            "firstName": props.currUser.firstName,
            "cleanedText": currentPrompt
        }, {
            headers: {
                Authorization: props.currUser.authToken,
            }
        }).then((response) => {


            const responseToSet = initial ?
                [{
                    message: response.data,
                    type: "bot"
                }] :
                incomingPrompt && incomingPrompt.length > 0
                    ?
                    [{
                        message: response.data,
                        type: "bot"
                    }, {
                        message: incomingPrompt,
                        type: "user"
                    }, ...qPilotResponse]
                    : [{message: response.data, type: "bot"}, ...qPilotResponse]

            setQPilotResponse(responseToSet)

            initial && setIsInitializing(false)

            setIsRunningQPilot(false)
        }).catch((error) => {
            !initial && setIsRunningQPilot(false)
            initial && setIsInitializing(false)
            console.log(`Error running chat: ${error}`)
        })
    }


    useEffect(() => {
        if (props.showQPilotChat && qPilotResponse.length === 0) {
            sendChat(true)
        }
    }, [props.showQPilotChat])


    const [commandKeyDown, setCommandKeyDown] = React.useState(false)

    function handler({key}) {
        if (String(key) === 'Meta') {
            setCommandKeyDown(!commandKeyDown)
        }
        if (commandKeyDown && String(key) === "Enter" && currentPrompt && currentPrompt.length > 0) {
            sendChat(false)
        }
    }

    useEventListener('keydown', handler);

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [qPilotResponse, isRunningQPilot]);

    const suggestedQuestions = [{
        prompt: "Why is the Business Digest view useful?",
        id: 1
    }]

    const handleAddSuggestedPrompt = (e, data) => {
        setCurrentPrompt(data.prompt)
        suggestedQuestions.filter(item => item.id !== data.id)
    }


    const remarkMathOptions = {
        singleDollarTextMath: false,
    };

    function getOS() {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    const operatingSystem = getOS()

    return (

        <Drawer
            open={true}
            variant={"permanent"}
            anchor={"right"}
            sx={{
                zIndex: 1199,
                width: props.qPilotDrawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    backgroundColor: "rgb(249, 249, 249)",
                    width: props.qPilotDrawerWidth,
                    boxSizing: "border-box"
                }
            }}
        >
            <Box sx={{pt: "70px", height: props.isMobile ? `30vh` : `calc(100vh - 70px)`}}>
                {<Tooltip placement="left" title={<span
                    style={{fontSize: "1.35em"}}>{!props.showQPilotChat ? "Show QPilot" : "Hide QPilot"}</span>}>
                    <ToggleButton
                        size={"small"}
                        value={"open"}
                        sx={{padding: 0}}
                        onClick={props.handleShowQPilotChatChange}>
                        {!props.showQPilotChat ? <Avatar
                                variant={"rounded"}
                                size={"small"}
                                src={QBearMascot}
                                sx={{color: "#1de9b6", backgroundColor: "#272931", width: 28, height: 28}}
                            />
                            :
                            <Box sx={{
                                color: "#fff",
                                backgroundColor: "#272931",
                                width: 28,
                                height: 28,
                                pt: "4px",
                                borderRadius: "3px",
                                '&:hover': {
                                    backgroundColor: "#333",
                                    color: "#999"
                                }
                            }}>
                                <AiOutlineCaretRight/>
                            </Box>}
                    </ToggleButton>
                </Tooltip>
                }

                {props.showQPilotChat &&
                    <Box sx={{width: "100%", height: "65vh"}}>
                        {isInitializing &&
                            <Grid container spacing={3} alignItems={"center"} justifyContent={"center"}><Grid item
                                                                                                              xs={12}
                                                                                                              lg={12}>
                                <Typography sx={{textAlign: "center"}}>
                                    Initializing QPilot...
                                </Typography>
                            </Grid></Grid>}
                        <Alert
                            icon={false}
                            style={{
                                backgroundColor: "rgb(249, 249, 249)",
                                color: "#000000",
                                // whiteSpace: 'pre-line',
                                height: "100%",
                                // maxHeight: "60vh",
                                padding: "0px",
                            }}
                            severity={"success"}>
                            <div style={{
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column-reverse",

                            }}>
                                {!isInitializing &&
                                    qPilotResponse.map((item, i) =>
                                        <ListItem key={"chat" + i} sx={{alignItems: "flex-start"}}>
                                            {qPilotResponse.length > 0 &&
                                                <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
                                                    <Avatar
                                                        variant={"rounded"}
                                                        size={"small"}
                                                        src={item.type === "bot" ? QBearMascot : (item.type && item.type) === "user" ? undefined : QBearMascot}
                                                        sx={{
                                                            color: "#1de9b6",
                                                            backgroundColor: "#272931",
                                                            width: 28,
                                                            height: 28,
                                                        }}
                                                    >{props.currUser.firstName.slice(0, 1) + props.currUser.lastName.slice(0, 1)}</Avatar>
                                                </ListItemAvatar>
                                            }

                                            <div style={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                paddingLeft: "0.5em",
                                                paddingRight: "0.5em",
                                                width: "100%",
                                                border: item.type === "bot" ? "0px #b6b6b6 solid" : "1px #b6b6b6 solid",
                                                borderRadius: "3px",
                                                backgroundColor: item.type === "bot" ? "#b6b6b6" : undefined
                                            }}><Markdown
                                                remarkPlugins={[[remarkMath, remarkMathOptions]]}
                                                rehypePlugins={[rehypeRaw, rehypeKatex]}
                                                className={"QPilotStyles"}>{item.message}</Markdown>
                                            </div>
                                        </ListItem>
                                    )}
                            </div>
                            <div ref={messagesEndRef}/>


                            {isRunningQPilot &&
                                <ListItem sx={{alignItems: "flex-start"}} key={"chat-incoming"}><ListItemAvatar
                                    sx={{p: 0, m: 0, minWidth: 34, paddingTop: "4px"}}>
                                    <Avatar
                                        variant={"rounded"}
                                        size={"small"}
                                        src={QBearMascot}
                                        sx={{
                                            color: "#1de9b6",
                                            backgroundColor: "#272931",
                                            width: 34,
                                            height: 34
                                        }}
                                    />
                                </ListItemAvatar>

                                    <div style={{height: 36, paddingTop: 4, paddingLeft: "6px"}}>
                                        Thinking...
                                        {/*<CircularProgress size={18}/>*/}
                                    </div>
                                </ListItem>
                            }
                        </Alert>
                        <Box sx={{
                            position: "sticky", bottom: 0, backgroundColor: "rgb(249, 249, 249)", paddingTop: "1em",
                            paddingBottom: "1em",
                            paddingLeft: "0.75em",
                            paddingRight: "0.75em",
                        }}>
                            <Grid container pt={2}>
                                <Grid item xs={12}>
                                    <TextField sx={{fontSize: 12}} fullWidth onChange={handleCurrentPrompt}
                                               size={"small"}
                                               maxRows={3}
                                               autoFocus={true}
                                               multiline
                                               placeholder={"Message QPilot..."}
                                               value={currentPrompt}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={"caption"}>{operatingSystem === "Mac OS" ? "command + Enter to send" : operatingSystem === "Windows" ? "CTRL + Enter to send" : null} </Typography>
                                </Grid>
                                {/*{suggestedQuestions.map(item => <Chip key={item.id}*/}
                                {/*                                      onClick={(e) => handleAddSuggestedPrompt(e, item)}*/}
                                {/*                                      label={item.prompt}/>)}*/}
                                <Grid container spacing={1} alignItems={"space-between"} sx={{pt: "4px"}}>
                                    <Grid item pt={1} pb={1}>
                                        <Button onClick={(e) => sendChat(false, e)} disabled={!currentPrompt}
                                                variant={"contained"}>Send</Button>
                                    </Grid>
                                    <Grid item pt={1} pb={1}>
                                        <Button onClick={props.handleShowQPilotChatChange}
                                                variant={"outlined"}>Hide QPilot</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*</form>*/}
                        </Box>
                    </Box>
                }
            </Box>
        </Drawer>

    )
}