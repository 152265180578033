import React from "react";
import {Route, Redirect, useLocation} from "react-router-dom";
import RolePermissions from "../RolePermissions";
import PlanPermissions from "../PlanPermissions";
import {useAppContext} from "../libs/appContextLib";
import {get} from 'lodash';
import CssBaseline from "@mui/material/CssBaseline";

export default function AuthenticatedRoute({component: C, ...rest}) {

    const {isAuthenticated} = useAppContext();
    const {currUser} = useAppContext();
    const {subscriptionStatus} = useAppContext();
    const location = useLocation();
    const {pathname, search} = useLocation();

    const subscriptionName = get(subscriptionStatus, "stripe_subscription.stripe_plan_nickname", null)
    const cleanSubscriptionName = () => {

        try {
            if (currUser.customerId) {
                if (PlanPermissions && Object.keys(PlanPermissions) && Object.keys(PlanPermissions).indexOf(currUser.customerId) > -1) {

                    return currUser.customerId
                } else if (subscriptionName && subscriptionName !== "none") {
                    return subscriptionName.indexOf("professional") > -1 ?
                        "professional"
                        : subscriptionName.indexOf("basic") > -1 ?
                            "basic"
                            : subscriptionName.indexOf("enterprise") > -1 ?
                                "enterprise"
                                : subscriptionName.indexOf("QF-BUSINESS") > -1 ?
                                    "professional"
                                    : subscriptionName.indexOf("QFLOW-ACTON") > -1 ?
                                        "professional"
                                        : "professional"
                } else return "professional"
            }
        } catch (error) {
            console.log("subscription error", error)
            return "professional"
        }
    }

    // console.log("cleanSubscriptionName", cleanSubscriptionName())

    const initSlug = location.pathname.split("/");
    const slug = initSlug[1];
    const finalSubscriptionName = cleanSubscriptionName()

    // console.log("finalSubscriptionName", finalSubscriptionName)

    const companySubscriptionHasPermission =
        slug === "access-denied" || slug === "" ?
            true
            : PlanPermissions[finalSubscriptionName] ?
                PlanPermissions[finalSubscriptionName][slug] ?
                    PlanPermissions[finalSubscriptionName][slug] &&
                    PlanPermissions[finalSubscriptionName][slug]['hasAccess']
                    : true
                : true;

    const userRoleHasPermission =
        slug === "access-denied" || slug === "" ?
            true
            : RolePermissions[currUser.role] ?
                RolePermissions[currUser.role][slug] ?
                    RolePermissions[currUser.role][slug] &&
                    RolePermissions[currUser.role][slug]['hasAccess']
                    : true
                : true;


    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ?
                    !companySubscriptionHasPermission ?
                        <Redirect
                            to={'/access-denied/plan'}
                        />
                        :
                        !userRoleHasPermission ?
                            <Redirect
                                to={'/access-denied/user'}
                            />
                            :
                            <React.Fragment>
                                <CssBaseline/>
                                <C {...props} slug={slug}
                                   cleanSubscriptionName={cleanSubscriptionName()}/>
                            </React.Fragment>
                    : <Redirect
                        to={`/login?redirect=${pathname}${search && search}`}
                    />
            }
        />
    );
}